import React, { useState } from "react";
import classNames from "classnames";
import { PendingLoader } from "svgIcon";
import {
  ChainSUI,
  getEBChains,
  getSolanaChains
} from 'utils/helper';
import SolanaWalletComponent from "../SolanaWalletComponent";
import SuiWalletComponent from "../SuiWalletComponent";
import BlastWalletComponent from "../BlastWalletComponent";
import { useStyles } from "./style";

const PaymentWalletComponent = ({
  amount,
  setManageDigest,
  generateJsonContent,
  setIsPaymentSuccess,
  manageChainAmount
}: any) => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [manageLabel, setManageLabel] = useState("");

  const onPaymentSuccess = (
    content: any,
    digest: any,
    sender: any,
    getKey: any
  ) => {
    setManageDigest(digest);
    setLoading(false);
    setIsPaymentSuccess(true);
  };

  const renderChainComponent = (getChainName: any) => {
    const isSolanaChains = getSolanaChains.includes(getChainName);
    if (isSolanaChains) {
      return (
        <SolanaWalletComponent
          amount={amount}
          generateJsonContent={generateJsonContent}
          setLoading={setLoading}
          setManageLabel={setManageLabel}
          onPaymentSuccess={onPaymentSuccess}
        />
      );
    }
    const isEBChains = getEBChains.includes(getChainName);
    if (isEBChains) {
      return (
        <BlastWalletComponent
          generateJsonContent={generateJsonContent}
          setLoading={setLoading}
          setManageLabel={setManageLabel}
          onPaymentSuccess={onPaymentSuccess}
        />
      );
    }
    if (getChainName == ChainSUI) {
      return (
        <SuiWalletComponent
          manageChainAmount={manageChainAmount}
          generateJsonContent={generateJsonContent}
          setLoading={setLoading}
          setManageLabel={setManageLabel}
          onPaymentSuccess={onPaymentSuccess}
        />
      )
    }
  };

  return (
    <div className={classNames(classes.conWallet, "text-center")}>
      {isLoading && (
        <div className="py-5">
          <div className="d-flex justify-content-center">
            <PendingLoader />
          </div>
          <h4 className="mt-2">Process to connecting...</h4>
        </div>
      )}
      {!isLoading && (
        <div className="conBtn">
          {renderChainComponent(generateJsonContent.blockchain)}
        </div>
      )}
    </div>
  );
};

export default PaymentWalletComponent;
