import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { ToastContainer } from "react-toastify";
import { baseUrl } from "utils/env";
import { getCoinAmount } from "utils/helper";
import { customDecrypt } from 'utils/encryptDecrypt';
import ContentComponent from "../ContentComponent";
import SuccessComponent from "../SuccessComponent";
import { coinFields } from './constant';

const CheckoutComponent = () => {
  const [transactionContent, setTransactionContent] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [manageDigest, setManageDigest] = useState("");
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  // const [showIsSuccess, setShowIsSuccess] = useState(false);
  // const [showQr, setShowQr] = useState(false);
  // const [storedId, setStoreId] = useState("");
  const [manageChainAmount, setManageChainAmount] = useState({
    ...coinFields
  });

  useEffect(() => {
    const fetchTransaction = async () => {
      try {
        const pathname = window.location.pathname;
        const modifiedInput = pathname.replace("/checkout/", "");
        if (modifiedInput !== null && modifiedInput !== undefined && modifiedInput !== "") {
          let decodedUrl = decodeURIComponent(decodeURIComponent(modifiedInput));
          const decryptValue = await customDecrypt(decodedUrl);
          if (decryptValue) {
            try {
              const response = await fetch(`${baseUrl}/linkpay/${decryptValue}`);
              if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
              }
              const result = await response.json();
              if (result && result.status === true) {
                const { data } = result;
                if (data.status === "pending") {
                  getCoinAmount(setManageChainAmount);
                  setTransactionContent(data);
                } else {
                  setTransactionContent(data);
                  setManageDigest(data.signature);
                  setIsPaymentSuccess(true);
                }
              }
            } catch (err: any) {
              setError(err.message);
            } finally {
              setLoading(false);
            }
          }
        }
      } catch (err: any) {
        setError(err.message);
      }
    };
    fetchTransaction();
  }, [window.location.pathname]);


  if (loading)
    return (
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    );
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="my-3 my-md-4 my-lg-5">
      <ToastContainer />
      {Object.keys(transactionContent).length !== 0 && (
        <div className="container">
          {isPaymentSuccess && manageDigest !== "" ? (
            <SuccessComponent
              generateJsonContent={transactionContent}
              manageDigest={manageDigest}
            />
          ) : (
            <ContentComponent
              transactionContent={transactionContent}
              manageChainAmount={manageChainAmount}
              setManageDigest={setManageDigest}
              setIsPaymentSuccess={setIsPaymentSuccess}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default CheckoutComponent;
