import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import BigNumber from "bignumber.js";
import { Image } from "antd";
import { PublicKey, Keypair, Connection } from "@solana/web3.js";
import {
    findReference,
    validateTransfer,
    encodeURL,
    createQR,
} from "@solana/pay";
import {
    ChainSOL,
    ChainSolanaBONK,
    ChainSolanaJUP,
    ChainSolanaPYTH,
    ChainSolanaUSDC,
    ChainSolanaUSDT,
    ChainSolanaWEN,
    solTokenAddresses,
    toastMessage,
} from "utils/helper";
import PaymentCouldntFetchComponent from "../PaymentCouldntFetchComponent";
import { useStyles } from "components/PaymentOptions/PayWithQR/PaymentQRComponent/style";

const SolanaQRComponent = ({
    amount,
    generateJsonContent,
    onPaymentSuccess
}: any) => {
    const classes = useStyles();
    const [qrStatus, setQrStatus] = useState(0);
    const [qrKey, setQrKey] = useState({});
    const [qrString, setQrString] = useState("");
    const [showTiming, setShowTiming] = useState(90);
    const refIsReloadInProgress = useRef(false);
    const [showTxnInput, setShowTxnInput] = useState(false);
    const [qrCodeURL, setQrCodeURL] = useState("");

    const subSOLCoinNames = [
        ChainSolanaUSDC,
        ChainSolanaUSDT,
        ChainSolanaPYTH,
        ChainSolanaBONK,
        ChainSolanaWEN,
        ChainSolanaJUP,
    ];
    const isItSolSubTokens = subSOLCoinNames.includes(
        generateJsonContent.blockchain
    );

    let checkData = 1;
    let timeCheck = 90;

    const onfetchSolanaTransaction = async (
        content: any,
        randomKey: any,
        newReference: any
    ) => {
        try {
            // const SolRPC =
            //   "https://mainnet.helius-rpc.com/?api-key=af28767a-214e-498f-9b5a-67c30bb6ed81";
            const SolRPC = "https://rpc.ankr.com/solana/c8f645fb306763c1564f37ffa22b2cac81d7e55081aa6aa8abce3931d55d4888"
            const connection = new Connection(SolRPC, "confirmed");

            if (!newReference) {
                toastMessage("No reference found.");
                return;
            }
            let tokenAddress;
            if (isItSolSubTokens) {
                const usdcTokenAddress = await solTokenAddresses(
                    generateJsonContent.blockchain
                );
                if (usdcTokenAddress) {
                    tokenAddress = new PublicKey(usdcTokenAddress);
                }
            }
            const splToken = tokenAddress !== null ? tokenAddress : undefined;
            const signatureInfo = await findReference(connection, newReference);
            const isValid = await validateTransfer(
                connection,
                signatureInfo.signature,
                {
                    recipient: new PublicKey(content.merchant_address),
                    amount: new BigNumber(content.amount),
                    // splToken: new PublicKey(splToken as any),
                    splToken: splToken,
                }
            );

            if (
                isValid &&
                isValid.transaction &&
                isValid.transaction.message &&
                isValid.transaction.message.accountKeys[0]
            ) {
                const getSender = isValid.transaction.message.accountKeys[0].toBase58();
                toastMessage("Payment validated successfully!");
                onPaymentSuccess(
                    content,
                    signatureInfo.signature,
                    getSender,
                    randomKey
                );
                checkData += 1;
            }
        } catch (error) {
            if (checkData == 1 && timeCheck !== 0) {
                timeCheck -= 1;
                setShowTiming(timeCheck);
                setTimeout(() => {
                    onfetchSolanaTransaction(content, randomKey, newReference);
                }, 1500);
            } else {
                setQrStatus(4);
            }
        }
    };

    const generateQRCodeAndSetState = async (
        generateJsonContent: any,
        splToken: any,
        newReference: any
    ) => {
        const recipientPublicKey = new PublicKey(
            generateJsonContent.merchant_address
        );
        const newSolanaQrPaymentObject = encodeURL({
            recipient: recipientPublicKey,
            amount: new BigNumber(generateJsonContent.amount),
            splToken:
                splToken !== undefined ? new PublicKey(splToken as any) : splToken,
            reference: newReference,
            label: generateJsonContent.merchant_name,
            message: "Thanks for paying using PocketPay!",
            memo: `${generateJsonContent.order_id}`,
        });

        const qr = createQR(newSolanaQrPaymentObject);
        const qrBlob = await qr.getRawData("png");

        if (!qrBlob) return;

        const reader = new FileReader();
        reader.onload = (event) => {
            if (typeof event.target?.result === "string") {
                setQrCodeURL(event.target.result);
            }
        };
        reader.readAsDataURL(qrBlob);

        const commerceString = JSON.stringify(generateJsonContent);
        setQrKey(generateJsonContent);
        setQrString(commerceString);
    };

    const onLoadGenerateContent = async (newReference: any) => {
        if (generateJsonContent.blockchain === ChainSOL) {
            await generateQRCodeAndSetState(
                generateJsonContent,
                undefined,
                newReference
            );
            return;
        }
        if (isItSolSubTokens) {
            const usdcTokenAddress = solTokenAddresses(
                generateJsonContent.blockchain
            );

            if (usdcTokenAddress) {
                await generateQRCodeAndSetState(
                    generateJsonContent,
                    usdcTokenAddress,
                    newReference
                );
                return;
            }
        }
        const commerceString = JSON.stringify(generateJsonContent);
        setQrKey(generateJsonContent);
        setQrString(commerceString);
    };

    const onLoadGenerateQr = async () => {
        setQrStatus(1);
        setShowTiming(90);
        setQrString("");

        const newReference = new Keypair().publicKey;
        onLoadGenerateContent(newReference);
        onfetchSolanaTransaction(
            generateJsonContent,
            generateJsonContent.order_id,
            newReference
        );
    };

    useEffect(() => {
        onLoadGenerateQr();
    }, []);

    return (
        <div className={classNames(classes.payQr, "my-2")}>
            {qrString !== "" && (
                <>
                    <h4 className="mt-0 mb-2 fontWeight700 px-md-5 text-dark text-center">
                        Pay using SolanaPay compatible wallet
                    </h4>
                    {qrStatus === 4 ? (
                        <PaymentCouldntFetchComponent
                            qrKey={qrKey}
                            onLoadGenerateQr={onLoadGenerateQr}
                            setShowTxnInput={setShowTxnInput}
                        />
                    ) : (
                        <Image
                            src={qrCodeURL}
                            title="Scan to pay"
                            alt="Scan to pay"
                            width={260}
                            height={260}
                            preview={false}
                        />
                    )}
                    <p className="mb-0 mt-1">QR Expires in {showTiming} seconds</p>
                    <div
                        className={classNames(classes.cainList, "justify-content-center")}
                    >
                        <div className="clItems mt-4 bg-ghostwhite p-3 rounded">
                            <p className="m-0 text-dark fontWeight600">Payable Amount</p>
                            <h4 className="m-0 fontWeight800">
                                {Object.keys(generateJsonContent).length > 0 && (
                                    <span
                                        className="text-primary fontWeight700 mx-1"
                                        style={{ position: "relative", top: "1px" }}
                                    >{`${amount} ${generateJsonContent?.blockchain}`}</span>
                                )}
                            </h4>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default SolanaQRComponent;
