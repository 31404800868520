import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import classNames from "classnames";
import { QRCodeCanvas } from "qrcode.react";
import { AppleIcon, PlayStoreIcon } from "svgIcon";
import { networkUrl, packageId } from "containers/CheckoutContainer/constant";
import PaymentCouldntFetchComponent from "../PaymentCouldntFetchComponent";
import PaymentVerifyBoxComponent from "../PaymentVerifyBoxComponent";
import { useStyles } from "components/PaymentOptions/PayWithQR/PaymentQRComponent/style";

const SuiQRComponent = ({
    amount,
    setManageDigest,
    generateJsonContent,
    onPaymentSuccess
}: any) => {
    const classes = useStyles();
    const [qrStatus, setQrStatus] = useState(0);
    const [qrKey, setQrKey] = useState({});
    const [qrString, setQrString] = useState("");
    const [showTiming, setShowTiming] = useState(90);
    const refIsReloadInProgress = useRef(false);
    const [showTxnInput, setShowTxnInput] = useState(false);

    let checkData = 1;
    let timeCheck = 90;

    const onfetchLastSuiTransaction = (content: any, randomKey: any) => {
        if (refIsReloadInProgress.current) {
            return;
        }
        refIsReloadInProgress.current = true;

        const data = {
            method: "suix_queryTransactionBlocks",
            jsonrpc: "2.0",
            params: [
                {
                    filter: {
                        InputObject: packageId,
                    },
                    options: {
                        showEffects: true,
                        showInput: true,
                    },
                },
                null,
                1,
                true,
            ],
            id: "1",
        };

        try {
            axios
                .post(networkUrl, data)
                .then(({ data }) => {
                    const { result } = data;
                    if (result) {
                        const { data } = result;
                        if (
                            data &&
                            data[0] &&
                            data[0].transaction &&
                            data[0].transaction.data.transaction.inputs.length === 5 &&
                            data[0].transaction.data.transaction.inputs[1].value ===
                            randomKey.toString() &&
                            data[0].transaction.data.transaction.inputs[4].value ==
                            content.amount * 1000000000 &&
                            data[0].transaction.data.transaction.inputs[3].value ==
                            content.merchant_address
                        ) {
                            if (
                                data &&
                                data[0] &&
                                data[0].effects &&
                                data[0].effects.status &&
                                data[0].effects.status.status == "success"
                            ) {
                                onPaymentSuccess(
                                    content,
                                    data[0].digest,
                                    data[0].transaction.data.sender,
                                    randomKey
                                );
                                checkData += 1;
                            } else {
                                setQrStatus(3);
                            }
                        } else {
                            if (checkData == 1 && timeCheck !== 0) {
                                timeCheck -= 1;
                                setShowTiming(timeCheck);
                                setTimeout(() => {
                                    onfetchLastSuiTransaction(content, randomKey);
                                }, 1500);
                            } else {
                                setQrStatus(4);
                            }
                        }
                    }
                })
                .catch((error: any) => {
                    console.log("OOPS! The SUI network may be down");
                });
        } catch {
            console.log("OOPS! The SUI network may be down");
        }
        refIsReloadInProgress.current = false;
    };

    const onLoadGenerateQr = async () => {
        setQrStatus(1);
        setShowTiming(90);

        const commerceString = JSON.stringify(generateJsonContent);
        setQrKey(generateJsonContent);
        setQrString(commerceString);

        onfetchLastSuiTransaction(
            generateJsonContent,
            generateJsonContent.order_id
        );
    };

    useEffect(() => {
        onLoadGenerateQr();
    }, []);

    return (
        <div className={classNames(classes.payQr, "my-2")}>
            {qrString !== "" && (
                <>
                    <h4 className="mt-0 mb-2 fontWeight700 px-md-5 text-dark text-center">
                        Pay using SolanaPay compatible wallet
                    </h4>
                    {qrStatus === 4 ? (
                        <PaymentCouldntFetchComponent
                            qrKey={qrKey}
                            onLoadGenerateQr={onLoadGenerateQr}
                            setShowTxnInput={setShowTxnInput}
                        />
                    ) : (
                        <QRCodeCanvas
                            className="ml-auto mr-auto"
                            title="Scan to pay"
                            value={qrString}
                            width={260}
                            height={260}
                            size={260}
                        />
                    )}
                    <p className="mb-0 mt-1">QR Expires in {showTiming} seconds</p>
                    <div
                        className={classNames(classes.cainList, "justify-content-center")}
                    >
                        <div className="clItems mt-4 bg-ghostwhite p-3 rounded">
                            <p className="m-0 text-dark fontWeight600">Payable Amount</p>
                            <h4 className="m-0 fontWeight800">
                                {Object.keys(generateJsonContent).length > 0 && (
                                    <span
                                        className="text-primary fontWeight700 mx-1"
                                        style={{ position: "relative", top: "1px" }}
                                    >{`${amount} ${generateJsonContent?.blockchain}`}</span>
                                )}
                            </h4>
                        </div>
                    </div>
                </>
            )}
            {qrStatus !== 1 && showTxnInput && (
                <PaymentVerifyBoxComponent
                    onPaymentSuccess={onPaymentSuccess}
                    qrKey={qrKey}
                    setQrStatus={setQrStatus}
                    setManageDigest={setManageDigest}
                />
            )}
        </div>
    )
}

export default SuiQRComponent