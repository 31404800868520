const EncryptionPassword = 123455869897897344352345546878984735;

function xorEncryptDecrypt(input: string, key: number): string {
  let output = '';
  for (let i = 0; i < input.length; i++) {
    output += String.fromCharCode(input.charCodeAt(i) ^ key);
  }
  return output;
}

function customBase64Decode(str: string): string {
  let decoded = str.replace(/-/g, '/').replace(/_/g, '+');
  while (decoded.length % 4 !== 0) {
    decoded += '=';
  }
  return atob(decoded);
}

export const customDecrypt = (encodedData: any) => {
  const decodedData: string = customBase64Decode(encodedData);
  const decryptedData: string = xorEncryptDecrypt(decodedData, EncryptionPassword);
  return decryptedData;
}