import React, { useRef, useState } from "react";
import { Col, Row } from "antd";
import { ReCAPTCHA } from "react-google-recaptcha";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ypIcnSvg from "assets/images/ypIcn.svg";
import { BtnSVGIcn, CopySVGIcn } from "svgIcon";
import ButtonComponent from "components/FormsComponents/ButtonComponent";
import SkeletonComponent from "components/FormsComponents/SkeletonComponent";
import {
  requiredField,
  requiredMandatoryField,
} from "containers/PaymentLinkContainer/constant";
import {
  checkAllRequiredFieldsWithKey,
  emailValidationExp,
} from "utils/FormValidation/requiredFields";
import { baseUrl } from "utils/env";
import {
  selectedChainIcon,
  shortenAddress,
  toastMessage,
  validateAddressWithChain,
} from "utils/helper";
import RecaptchaComponet from '../RecaptchaComponet';

const LinkProceedComponent = ({
  formData,
  setFormData,
  setGenerateLink,
  setShowLinkModal,
  errors,
  setErrors,
}: any) => {
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const [isClicked, setIsClicked] = React.useState(false);
  const [btnLoader, setBtnLoader] = useState(false);

  const handleRecaptchaChange = () => {
    setIsClicked(true);
  };

  const checkAllErrors = () => {
    let errorObject = {
      ...checkAllRequiredFieldsWithKey(requiredMandatoryField, formData),
    };
    setErrors({ ...errors, ...errorObject });
    return Object.values(errorObject).every((errorMsg) => !errorMsg);
  };

  const handleAPISubmit = async () => {
    setBtnLoader(true);
    const token = recaptchaRef.current?.getValue();
    const payload = {
      recaptchaToken: token,
    };
    recaptchaRef.current?.reset();
    const tokenString =
      formData.stokenlist &&
      formData.stokenlist.map((token: any) => `${token}`).join(",");

    try {
      const { schainlist, stokenlist, ...newObj } = formData;
      const response = await fetch(`${baseUrl}/linkpay`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...newObj,
          coin: tokenString,
          blockchain: formData.schainlist,
          recaptchaToken: token,
        }),
      });
      if (!response.ok) {
        throw new Error("Failed to create transaction");
      }
      const data = await response.json();
      if (data && data.txId) {
        const transactionLink = `https://pay.pocketpay.finance/checkout/${data.txId}`;
        setGenerateLink(transactionLink);
        setFormData({ ...requiredField });
        setShowLinkModal(true);
        setIsClicked(false);
        setBtnLoader(false);
      }
    } catch (error: any) {
      setBtnLoader(false);
    }
  };

  const onSubmitForm = () => {
    if (checkAllErrors()) {
      if (formData.schainlist === "") {
        toastMessage("Please select any network");
        return;
      }
      if (formData.stokenlist.length === 0) {
        toastMessage("Please select coin");
        return;
      }
      if (formData.amount == "0") {
        setErrors({
          ...errors,
          ["amount"]: "Amount not should be 0",
        });
        return;
      }
      if (formData.sender_email !== "") {
        if (!emailValidationExp.test(formData.sender_email)) {
          setErrors({
            ...errors,
            ["sender_email"]: "Please enter a valid email properly",
          });
          return;
        }
      }
      if (formData.receiver_email !== "") {
        if (!emailValidationExp.test(formData.receiver_email)) {
          setErrors({
            ...errors,
            ["receiver_email"]: "Please enter a valid email properly",
          });
          return;
        }
      }
      if (formData.receiver_address !== "") {
        const verifyReceiverAddress = validateAddressWithChain(
          formData.receiver_address,
          formData.schainlist
        );
        if (!verifyReceiverAddress) {
          setErrors({
            ...errors,
            ["receiver_address"]: "Chain address is not correct",
          });
          return;
        }
      }
      if (!isClicked) {
        toastMessage("Please verify the captcha");
        return;
      }
      handleAPISubmit();
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="mwRPayment mb-4">
        <h4 className="mb-4">Proceed to Payment</h4>
        <div className="payList">
          <div className="plItems d-flex justify-content-between">
            <p>Payment Topic</p>
            <h4 className="text-end">{formData.link_name}</h4>
          </div>
          <div className="plItems d-flex justify-content-between">
            <p>Receiver Email</p>
            <h4 className="text-end">{formData.receiver_email}</h4>
          </div>
          <div className="plItems d-flex justify-content-between">
            <p>Receiver Address</p>
            <h4 className="text-end d-inline-flex align-items-center">
              {formData.receiver_address !== "" && (
                <>
                  <span>{shortenAddress(formData.receiver_address)}</span>
                  <CopySVGIcn
                    className="mb-1 ms-1 cursorPointer"
                    title="Copy"
                  />
                </>
              )}
            </h4>
          </div>
        </div>
        <hr className="dashed my-4" />
        <div className="youPay d-flex align-items-center justify-content-between">
          <div className="ypLe">
            <p>You have to Pay</p>
            <div className="ypLBo d-flex align-items-end">
              {formData.amount !== "" && <h4>$ {formData.amount}</h4>}
            </div>
          </div>
          <div className="ypRi">
            <img src={ypIcnSvg} alt="Icon" />
          </div>
        </div>
      </div>
      <div className="blurLine"></div>
      <div className="mwRChain mb-4">
        <Row gutter={{ xs: 20, sm: 20, md: 30, lg: 40 }}>
          <Col
            className="gutter-row"
            span={12}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
          >
            <div className="payList">
              <div className="plItems">
                <p className="mb-2">Selected Chain</p>
                {/* {formData.schainlist && formData.schainlist.length > 0 ? (
                  <div className="selectedList d-flex align-items-center">
                    {formData.schainlist
                      .slice(0, 4)
                      .map((chain: any, index: any) => {
                        return (
                          <div className="slItems" key={index}>
                            <div className="slIBox">
                              {selectedChainIcon(chain)}
                            </div>
                          </div>
                        );
                      })}
                    {formData.schainlist.length > 4 && (
                      <div className="slItems">
                        <div className="slIBox">
                          {formData.schainlist.length - 4}+
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="skeletonSelected d-flex flex-wrap align-items-center">
                    <div className="stSItems">
                      <SkeletonComponent />
                    </div>
                    <div className="stSItems">
                      <SkeletonComponent />
                    </div>
                    <div className="stSItems">
                      <SkeletonComponent />
                    </div>
                    <div className="stSItems">
                      <SkeletonComponent />
                    </div>
                  </div>
                )} */}
                {formData.schainlist && formData.schainlist !== "" ? (
                  <div className="selectedList d-flex align-items-center">
                    <div className="slItems" key={formData.schainlist}>
                      <div className="slIBox">
                        {selectedChainIcon(formData.schainlist)}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="skeletonSelected d-flex flex-wrap align-items-center">
                    <div className="stSItems">
                      <SkeletonComponent />
                    </div>
                    <div className="stSItems">
                      <SkeletonComponent />
                    </div>
                    <div className="stSItems">
                      <SkeletonComponent />
                    </div>
                    <div className="stSItems">
                      <SkeletonComponent />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col
            className="gutter-row"
            span={12}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
          >
            <div className="payList">
              <div className="plItems">
                <p className="mb-2">Selected Coin</p>
                {formData.stokenlist && formData.stokenlist.length > 0 ? (
                  <div className="selectedList d-flex align-items-center">
                    {formData.stokenlist
                      .slice(0, 4)
                      .map((token: any, index: any) => {
                        return (
                          <div className="slItems" key={index}>
                            <div className="slIBox">
                              {selectedChainIcon(token)}
                            </div>
                          </div>
                        );
                      })}
                    {formData.stokenlist.length > 4 && (
                      <div className="slItems">
                        <div className="slIBox">
                          {formData.stokenlist.length - 4}+
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="skeletonSelected d-flex flex-wrap align-items-center">
                    <div className="stSItems">
                      <SkeletonComponent />
                    </div>
                    <div className="stSItems">
                      <SkeletonComponent />
                    </div>
                    <div className="stSItems">
                      <SkeletonComponent />
                    </div>
                    <div className="stSItems">
                      <SkeletonComponent />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <RecaptchaComponet
        recaptchaRef={recaptchaRef}
        handleRecaptchaChange={handleRecaptchaChange}
      />
      <ButtonComponent
        extraClass="svgAnimationYX w-100"
        title="Generate Link"
        btnPrimaryFill
        onClick={() => onSubmitForm()}
      >
        {btnLoader ? "Processing..." : "Generate Link"}
        {!btnLoader && (
          <BtnSVGIcn
            style={{ width: "12px", height: "12px" }}
            className="aniArrow ml-2"
          />
        )}
      </ButtonComponent>
    </>
  );
};

export default LinkProceedComponent;
