import { createUseStyles } from "react-jss";
import breakpoints from "assets/breakpoints";

export const useStyles = createUseStyles({
  generateLinkModel: {
    "& .mwRBox": {
      position: "relative",
      "& .mwRPayment": {
        backgroundColor: "rgb(255 255 255 / 80%)",
        boxShadow: "0 10px 30px rgb(0 0 0 / 4%)",
        border: "1px solid var(--ghostwhite)",
        backdropFilter: "blur(30px)",
        position: "relative",
        borderRadius: "16px",
        padding: "20px",
        zIndex: "3",
        [breakpoints.SCREEN_LG_MIN]: {
          padding: "25px",
        },
      },
    },
    "& .copyCode": {
      backgroundColor: "rgb(89 128 220 / 8%)",
      border: "1px solid var(--white)",
      padding: "8px 8px 8px 16px",
      borderRadius: "8px",
      "& .ccIcn": {
        width: "40px",
        height: "40px",
        backgroundColor: "var(--white)",
        borderRadius: "8px",
        WebkitTransition: "all 0.2s ease-in-out",
        transition: "all 0.2s ease-in-out",
        "& svg": {
          "& > path": {
            WebkitTransition: "all 0.2s ease-in-out",
            transition: "all 0.2s ease-in-out",
          },
        },
        "&:hover": {
          backgroundColor: "var(--primary)",
          "& svg": {
            "& > path": {
              fill: "var(--white)",
            },
          },
        },
      },
    },
    "& .socialMedia": {
      "& p": {
        padding: "0 10px",
        position: "relative",
        color: "var(--lightGrey)",
        display: "block",
        "&:after": {
          content: "''",
          width: "100%",
          height: "1px",
          backgroundColor: "rgba(89, 128, 220,0.2)",
          right: "100%",
          top: "10px",
          position: "absolute",
        },
        "&:before": {
          content: "''",
          width: "100%",
          height: "1px",
          backgroundColor: "rgba(89, 128, 220,0.2)",
          left: "100%",
          top: "10px",
          position: "absolute",
        },
      },
      "& span": {
        width: "45px",
        height: "45px",
        borderRadius: "50%",
        border: "1px solid rgba(89, 128, 220,0.2)",
        transition: "0.3s all ease-in-out",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "rgba(89, 128, 220,0.05)",
          "& svg": {
            transform: "scale(1.1)",
          },
        },
        "& svg": {
          width: "18px",
          height: "18px",
          transition: "0.3s all ease-in-out",
        },
      },
    },
  },
});
