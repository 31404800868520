import React, { useState } from "react";
import classNames from "classnames";
import { CopySVGIcn, FacebookIcon, WhatsappIcon, MailIcon, ShareSVGIcn, SuccessLoader, TelegramIcon, TwitterIcon } from "svgIcon";
import { copyContent, shortenAddress } from "utils/helper";
import ButtonComponent from "components/FormsComponents/ButtonComponent";
import { useStyles } from "./style";

const LinkSharedComponent = ({ generateLink }: any) => {
  const classes = useStyles();
  const [showSLContent, setShowSLContent] = useState(false);
  return (
    <div className={classNames(classes.generateLinkModel)}>
      <div className="mwRBox">
        <div className="d-flex flex-column align-items-center">
          <SuccessLoader />
          <h3 className="mb-4">Link Generated</h3>
        </div>
        <div className="copyCode d-flex align-items-center justify-content-between mb-4">
          <span>{generateLink && shortenAddress(generateLink, 18, 12)}</span>
          <span
            className="ccIcn cursorPointer d-inline-flex align-items-center justify-content-center"
            onClick={() => copyContent(generateLink)}
            title="Copy"
          >
            <CopySVGIcn style={{ width: "14", height: "16" }} />
          </span>
        </div>
        <div className="d-flex align-items-center">
          <ButtonComponent
            extraClass="me-1 w-100"
            title="Share Link"
            btnPrimaryFill
            onClick={() => setShowSLContent(!showSLContent)}
          >
            <ShareSVGIcn className="aniArrow mr-2" />
            Share Link
          </ButtonComponent>
        </div>
        {showSLContent && (
          <div className='d-flex gap-15 flex-column align-items-center mt-3 socialMedia'>
            <p className="smText">Share link via</p>
            <div className='d-flex gap-15 align-items-center '>
              <span
                className="d-flex align-items-center justify-content-center"
                title="Email"
                onClick={() => window.open(`mailto:?body=${generateLink}`, "_blank")}
              >
                <MailIcon />
              </span>
              <span
                className="d-flex align-items-center justify-content-center"
                title="Facebook"
                onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${generateLink}`, "_blank")}
              >
                <FacebookIcon />
              </span>
              <span
                className="d-flex align-items-center justify-content-center"
                title="Whatsapp"
                onClick={() => window.open(`https://api.whatsapp.com/send?text=${generateLink}`, "_blank")}
              >
                <WhatsappIcon />
              </span>
              <span
                className="d-flex align-items-center justify-content-center"
                title="Twitter"
                onClick={() => window.open(`https://x.com/intent/tweet?url=${generateLink}`, "_blank")}
              >
                <TwitterIcon />
              </span>
              <span
                className="d-flex align-items-center justify-content-center"
                title="Telegram"
                onClick={() => window.open(`https://t.me/share/url?url=${generateLink}`, "_blank")}
              >
                <TelegramIcon />
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LinkSharedComponent;
