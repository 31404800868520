import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { BtnSVGIcn, CopySVGIcn, SuccessLoader } from "svgIcon";
import PocketDarkLogo from "assets/images/logo.svg";
import { baseUrl } from "utils/env";
import { checkCoin, copyContent, shortenAddress } from "utils/helper";
import { customDecrypt } from 'utils/encryptDecrypt';
import ButtonComponent from "components/FormsComponents/ButtonComponent";
import { useStyles } from "./style";

function SuccessComponent({ generateJsonContent, manageDigest }: any) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [url, setUrl] = useState("");

  const onRenderAddress = async (getDigest: any) => {
    const renderAddress = await checkCoin(
      generateJsonContent.blockchain,
      getDigest
    );
    if (!!renderAddress) {
      setUrl(renderAddress);
    }
  };

  const onSuccessAPI = async () => {
    const pathname = window.location.pathname;
    const modifiedInput = pathname.replace("/checkout/", "");
    if (modifiedInput !== null && modifiedInput !== undefined && modifiedInput !== "") {
      let decodedUrl = decodeURIComponent(decodeURIComponent(modifiedInput));
      const decryptValue = await customDecrypt(decodedUrl);
      if (decryptValue) {
        fetch(`${baseUrl}/linkpay`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: decryptValue,
            status: "completed",
            signature: manageDigest,
          }),
        }).then((response) => {
          response.json();
        });
      }
    }
  };

  useEffect(() => {
    onSuccessAPI();
    onRenderAddress(manageDigest);
  }, [generateJsonContent]);

  return (
    <Row gutter={{ xs: 10, sm: 10, md: 14, lg: 20 }} justify="center">
      <Col
        span={9}
        xs={{ span: 24 }}
        sm={{ span: 22 }}
        md={{ span: 20 }}
        lg={{ span: 12 }}
        xl={{ span: 9 }}
      >
        <div className="hLogo mb-4 d-flex justify-content-center">
          <img src={PocketDarkLogo} alt="" />
        </div>
        <div className={classNames(classes.merCard, "merCardCommon")}>
          <div className={classNames(classes.merCardImage)}>
            <SuccessLoader />
          </div>
          <h3 className="mb-4">
            Your order has been placed <br />
            successfully
          </h3>
          <div className="copyCode d-flex justify-content-center align-items-center gap-10 mb-4">
            <div>
              <span>View on explorer:{" "}</span>
              <span
                className={classNames(classes.merCardLink)}
                onClick={() => window.open(url, "_blank")}>
                {manageDigest && shortenAddress(manageDigest)}
              </span>
            </div>
            <span
              className="ccIcn cursorPointer d-inline-flex align-items-center justify-content-center"
              onClick={() => copyContent(url)}
              title="Copy"
            >
              <CopySVGIcn style={{ width: "14", height: "16" }} />
            </span>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <ButtonComponent
              extraClass="svgAnimationYX"
              title="Generate Link"
              btnPrimaryFill
              onClick={() => navigate("/", { replace: true })}
            >
              Generate Payment Link
              <BtnSVGIcn
                style={{ width: "12px", height: "12px" }}
                className="aniArrow ml-2"
              />
            </ButtonComponent>
          </div>
        </div>
        <div className="mt-3 d-flex justify-content-center">
          <p className="m-0 text-center smText">
            Powered by <strong>PocketPay Finance</strong>
          </p>
        </div>
      </Col>
    </Row>
  );
}
export default SuccessComponent;
