import React, { useRef, useState } from "react";
import { Col, Row } from "antd";
import { toast, Toaster } from "react-hot-toast";
import { CheckSVGIcn } from "svgIcon";
import TextAreaComponent from "components/FormsComponents/TextAreaComponent";
import SkeletonComponent from "components/FormsComponents/SkeletonComponent";
import TextBoxComponent from "components/FormsComponents/TextBoxComponent";
import LabelComponent from "components/FormsComponents/LabelComponent";
import { chainlist, tokenlist } from "containers/PaymentLinkContainer/constant";
import { selectedChainIcon, validateAddress } from "utils/helper";
import { emailValidationExp } from "utils/FormValidation/requiredFields";

const LinkFormComponent = ({
  formData,
  setFormData,
  errors,
  setErrors,
}: any) => {
  const toggleSelectedChain = (name: any) => {
    // multi-chain selection
    // if (formData.schainlist && formData.schainlist.includes(name)) {
    //   if (name === "Solana") {
    //     setFormData({
    //       ...formData,
    //       stokenlist: [],
    //       schainlist: formData.schainlist.filter(
    //         (chain: any) => chain !== name
    //       ),
    //     });
    //   } else {
    //     setFormData({
    //       ...formData,
    //       schainlist: formData.schainlist.filter(
    //         (chain: any) => chain !== name
    //       ),
    //     });
    //   }
    // } else {
    //   setFormData({ ...formData, schainlist: [...formData.schainlist, name] });
    // }
    if (name === formData.schainlist) {
      setFormData({
        ...formData,
        stokenlist: [],
        schainlist: "",
      });
    } else {
      setFormData({
        ...formData,
        stokenlist: [],
        schainlist: name,
      });
    }
  };

  const toggleSelectedTokens = (name: any) => {
    if (formData.stokenlist && formData.stokenlist.includes(name)) {
      setFormData({
        ...formData,
        stokenlist: formData.stokenlist.filter((token: any) => token !== name),
      });
    } else {
      setFormData({ ...formData, stokenlist: [...formData.stokenlist, name] });
    }
  };

  const handleInput = ({ target: { name, value } }: any) => {
    setFormData({ ...formData, [name]: value });
  };

  const removeError = ({ target: { name } }: any) => {
    setErrors({ ...errors, [name]: "" });
  };

  function handlePaste(event: any) {
    event.preventDefault(); // Prevent the default paste behavior
  }

  const handleName = ({ target: { name, value } }: any) => {
    if (value.length >= 25) {
      setErrors({
        ...errors,
        [name]: "Please enter merchant name upto 25 characters.",
      });
      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // const handleAmount = ({ target: { name, value } }: any) => {
  //   if (value && value.startsWith("-")) {
  //     setErrors({
  //       ...errors,
  //       [name]: "Amount should be in an integer",
  //     });
  //     return;
  //   }
  //   setFormData({ ...formData, [name]: value });
  // };

  const handleAmount = ({ target: { name, value } }: any) => {
    if (value !== " " && !/^\d*\.?\d*$/.test(value)) {
      setErrors({
        ...errors,
        [name]: "Amount should be numeric",
      });
      return;
    }
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleEmail = ({ target: { name, value } }: any) => {
    setFormData({ ...formData, [name]: value });
    if (!emailValidationExp.test(value) && value) {
      setErrors({
        ...errors,
        [name]: "Please enter a valid email properly",
      });
    } else {
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  const handleAddress = ({ target: { name, value } }: any) => {
    setFormData({ ...formData, [name]: value });
    const sAddress = validateAddress(value);
    if (!sAddress && value) {
      setErrors({
        ...errors,
        [name]: "Please fillup correct wallet address",
      });
    } else {
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  const handleMessage = ({ target: { name, value } }: any) => {
    if (value && value.length >= 250) {
      setErrors({
        ...errors,
        [name]: "Please enter description upto 250 characters.",
      });
      return;
    } else {
      setErrors({
        ...errors,
        [name]: "",
      });
    }
    setFormData({ ...formData, [name]: value });
  };

  const renderTokenList = () => {
    const selectedTokens = tokenlist.find(
      (item) => item.key === formData.schainlist
    );
    if (selectedTokens) {
      return selectedTokens.coin.map((coin) => {
        const isSelected =
          formData.stokenlist && formData.stokenlist.includes(coin);

        return (
          <div
            key={coin}
            className={`ncItems d-flex flex-wrap align-items-center ${isSelected && "selected"
              }`}
            onClick={() => toggleSelectedTokens(coin)}
          >
            <div className="ncIIcn">{selectedChainIcon(coin)}</div>
            <div className="ncIText">{coin}</div>
            {isSelected && <CheckSVGIcn className="ms-2" />}
          </div>
        );
      });
    }
  };

  return (
    <>
      <div className="createPL">
        <Toaster />
        <h3 className="mb-4">Create Payment Link</h3>
        <Row gutter={{ xs: 10, sm: 10, md: 20, lg: 30 }} className="mb-3">
          <Col
            className="gutter-row"
            span={12}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 12 }}
          >
            <TextBoxComponent
              type="text"
              title="Enter Link Name"
              label="Enter Link Name"
              mandatory
              name="link_name"
              onFocus={removeError}
              onChange={handleName}
              value={formData && formData.link_name}
              error={errors && errors.link_name}
            />
          </Col>
          <Col
            className="gutter-row"
            span={12}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 12 }}
          >
            <div className="d-flex align-items-end addAmount">
              <LabelComponent mandatory>Amount</LabelComponent>
              <div className="d-flex align-items-center aaInner">
                <h5>$</h5>
                <TextBoxComponent
                  type="text"
                  title="Amount"
                  extraClass="mb-0"
                  mandatory
                  name="amount"
                  onFocus={removeError}
                  onChange={handleAmount}
                  onPaste={handlePaste}
                  value={formData && formData.amount}
                  error={errors && errors.amount}
                />
              </div>
            </div>
          </Col>
        </Row>
        <h4 className="mb-1">Receiver Details</h4>
        <Row gutter={{ xs: 10, sm: 10, md: 20, lg: 30 }} className="mb-3">
          <Col
            className="gutter-row"
            span={12}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 12 }}
          >
            <TextBoxComponent
              type="text"
              title="Receiver Email"
              label="Receiver Email"
              mandatory
              name="receiver_email"
              onFocus={removeError}
              onChange={handleEmail}
              value={formData && formData.receiver_email}
              error={errors && errors.receiver_email}
            />
          </Col>
          <Col
            className="gutter-row"
            span={12}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 12 }}
          >
            <TextBoxComponent
              type="text"
              title="Wallet Address"
              label="Wallet Address"
              mandatory
              name="receiver_address"
              onFocus={removeError}
              onChange={handleAddress}
              value={formData && formData.receiver_address}
              error={errors && errors.receiver_address}
            />
          </Col>
        </Row>
        <h4 className="mb-1">Customer Details</h4>
        <TextBoxComponent
          type="text"
          title="Sender Email"
          label="Sender Email"
          mandatory
          name="sender_email"
          onFocus={removeError}
          onChange={handleEmail}
          value={formData && formData.sender_email}
          error={errors && errors.sender_email}
        />
        <div className="mb-4 mt-4">
          <h5 className="mb-1">Select Network</h5>
          <div className="netCoList d-flex flex-wrap align-items-start">
            {chainlist &&
              chainlist.map((name, index) => {
                const isSelected = formData.schainlist === name;
                return (
                  <div
                    className={`ncItems d-flex flex-wrap align-items-center ${isSelected && "selected"
                      }`}
                    onClick={() => toggleSelectedChain(name)}
                    title={name}
                    key={index}
                  >
                    <div className="ncIIcn">{selectedChainIcon(name)}</div>
                    <div className="ncIText">{name}</div>
                    {isSelected && <CheckSVGIcn className="ms-2" />}
                  </div>
                );
              })}
          </div>
        </div>
        <div className="mb-4">
          <h5 className="mb-1">Select Coin</h5>
          {formData.schainlist && formData.schainlist !== "" ? (
            <div className="netCoList d-flex flex-wrap">
              {renderTokenList()}
            </div>
          ) : (
            <div className="skeletonCoin d-flex flex-wrap align-items-center">
              <div className="stCItems">
                <SkeletonComponent />
              </div>
              <div className="stCItems">
                <SkeletonComponent />
              </div>
              <div className="stCItems">
                <SkeletonComponent />
              </div>
            </div>
          )}
          {/* {formData.schainlist && formData.schainlist.includes("Solana") ? (
            <div className="netCoList d-flex flex-wrap">
              <>
                {tokenlist &&
                  tokenlist.map((name) => {
                    const isSelected =
                      formData.stokenlist && formData.stokenlist.includes(name);
                    return (
                      <div
                        className={`ncItems d-flex flex-wrap align-items-center ${
                          isSelected && "selected"
                        }`}
                        onClick={() => toggleSelectedTokens(name)}
                      >
                        <div className="ncIIcn"> {selectedChainIcon(name)}</div>
                        <div className="ncIText">{name}</div>
                        {isSelected && <CheckSVGIcn className="ms-2" />}
                      </div>
                    );
                  })}
              </>
            </div>
          ) : (
            <>
              <div className="skeletonCoin d-flex flex-wrap align-items-center">
                <div className="stCItems">
                  <SkeletonComponent />
                </div>
                <div className="stCItems">
                  <SkeletonComponent />
                </div>
                <div className="stCItems">
                  <SkeletonComponent />
                </div>
              </div>
            </>
          )} */}
        </div>
        <div>
          <TextAreaComponent
            title="Message"
            label="Message"
            name="description"
            rows={3}
            onFocus={removeError}
            onChange={handleMessage}
            value={formData && formData.description}
            error={errors && errors.description}
          />
        </div>
      </div>
    </>
  );
};

export default LinkFormComponent;
