import {
  ChainSUI,
} from "utils/helper";
import SuiQRComponent from '../SuiQRComponent';
import SolanaQRComponent from '../SolanaQRComponent';

const PaymentQRComponent = ({
  amount,
  setManageDigest,
  generateJsonContent,
  setIsPaymentSuccess,
  setShowQR,
}: any) => {

  const onPaymentSuccess = (
    content: any,
    digest: any,
    sender: any,
    getKey: any
  ) => {
    setManageDigest(digest);
    setIsPaymentSuccess(true);
    setShowQR(false);
  };

  return (
    <>
      {generateJsonContent.blockchain === ChainSUI ? (
        <SuiQRComponent
          amount={amount}
          setManageDigest={setManageDigest}
          generateJsonContent={generateJsonContent}
          onPaymentSuccess={onPaymentSuccess}
        />
      ) : (
        <SolanaQRComponent
          amount={amount}
          generateJsonContent={generateJsonContent}
          onPaymentSuccess={onPaymentSuccess}
        />
      )}
    </>
  );
};

export default PaymentQRComponent;
