import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const RecaptchaComponet = ({
  recaptchaRef,
  handleRecaptchaChange,
}) => {
  const sitekey = "6Ld7f4EpAAAAAPB6TfEpz8d-vhlHa2Z4SUj-fLjH";
  return (
    <ReCAPTCHA
      className="mb-2"
      ref={recaptchaRef}
      sitekey={sitekey}
      onChange={handleRecaptchaChange}
    />
  );
};

export default RecaptchaComponet;
