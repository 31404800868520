import React, { useEffect, useState } from "react";
import { Col, Row, Select } from "antd";
import classNames from "classnames";
import { QrSvgIcon } from "svgIcon";
import {
  ChainBNB,
  ChainBase,
  ChainBlast,
  ChainLinea,
  ChainPolygon,
  ChainSOL,
  ChainSUI,
  ChainSolanaBONK,
  ChainSolanaJUP,
  ChainSolanaPYTH,
  ChainSolanaUSDC,
  ChainSolanaUSDT,
  ChainSolanaWEN,
  ChainTaiko,
  shortenAddress,
  selectedChainIcon,
  shortenValue,
  toastMessage,
  isSOLPBCoins,
  isWalletChain,
  isQRChain,
} from "utils/helper";
import {
  isClientNetwork,
  isCoinType,
  selectedTokenlist,
} from "containers/CheckoutContainer/constant";
import ModalComponent from "components/FormsComponents/ModalComponent";
import PaymentWalletComponent from "components/PaymentOptions/PayWithWallet/PaymentWalletComponent";
import PaymentQRComponent from "components/PaymentOptions/PayWithQR/PaymentQRComponent";
import ButtonComponent from "components/FormsComponents/ButtonComponent";
import { useStyles } from "./style";
import PocketDarkLogo from "assets/images/logo.svg";


const ContentComponent = ({
  transactionContent,
  manageChainAmount,
  setManageDigest,
  setIsPaymentSuccess,
}: any) => {
  const { Option } = Select;
  const classes = useStyles();
  const [subCoin, setSubCoin] = useState<any>([]);
  const [amount, setAmount] = useState<any>();
  const [qrContent, setQrContent] = useState<any>({});
  const [showQR, setShowQR] = useState(false);
  const [selectedChain, setSelectedChain] = useState(transactionContent.blockchain);

  const identifyIsCoin = isSOLPBCoins(selectedChain && selectedChain !== "" && selectedChain);
  // const identifyWalletCoin = isWalletChain(selectedChain && selectedChain !== "" && selectedChain);
  // const identifyQRCoin = isQRChain(selectedChain && selectedChain !== "" && selectedChain);
  const isValidAmount = amount !== undefined && amount !== 0 && amount !== Infinity;

  type CalculationFunction = (amount: number) => number | string;

  const calculationFunctions: Record<string, CalculationFunction> = {
    [ChainSUI]: (amount) => shortenValue(amount / manageChainAmount.sui),
    [ChainBlast]: (amount) => shortenValue(amount / manageChainAmount.blast),
    [ChainBase]: (amount) => shortenValue(amount / manageChainAmount.base),
    [ChainLinea]: (amount) => shortenValue(amount / manageChainAmount.linea),
    [ChainTaiko]: (amount) => shortenValue(amount / manageChainAmount.taiko),
    [ChainBNB]: (amount) => shortenValue(amount / manageChainAmount.bnb),
    [ChainPolygon]: (amount) => shortenValue(amount / manageChainAmount.polygon),
    [ChainSOL]: (amount) => shortenValue(amount / manageChainAmount.solana),
    [ChainSolanaUSDC]: (amount) => shortenValue(amount),
    [ChainSolanaUSDT]: (amount) => shortenValue(amount / manageChainAmount.usdt),
    [ChainSolanaPYTH]: (amount) => shortenValue(amount / manageChainAmount.pyth),
    [ChainSolanaBONK]: (amount) => shortenValue(amount / manageChainAmount.bonk),
    [ChainSolanaWEN]: (amount) => shortenValue(amount / manageChainAmount.wen),
    [ChainSolanaJUP]: (amount) => shortenValue(amount * manageChainAmount.jup),
  };

  const onGenerateQrContent = (getAmount: any) => {
    const randomKey = Math.floor(1000000 + Math.random() * 9000000);
    if (transactionContent.receiver_address) {
      if (selectedChain === ChainSUI) {
        const generateContent = {
          merchant_address: transactionContent.receiver_address,
          merchant_name: "Link Payment",
          order_id: randomKey,
          amount: getAmount,
          network: isClientNetwork,
          blockchain: selectedChain,
          coin_name: selectedChain,
          coin_type: isCoinType,
        };
        setQrContent(generateContent);
      }
      if (identifyIsCoin === true) {
        const generateContent = {
          merchant_address: transactionContent.receiver_address,
          merchant_name: "Link Payment",
          amount: getAmount,
          order_id: randomKey,
          network: isClientNetwork,
          blockchain: selectedChain,
        };
        setQrContent(generateContent);
      }
    }
  };

  useEffect(() => {
    if (selectedChain !== "" && selectedChain !== "Solana") {
      const calculationFunction = calculationFunctions[selectedChain];
      if (calculationFunction) {
        const calculatedAmount = calculationFunction(transactionContent.amount);
        setAmount(calculatedAmount && calculatedAmount);
        onGenerateQrContent(calculatedAmount && calculatedAmount);
      } else {
        toastMessage("Coming soon");
      }
    } else if (selectedChain !== "" && selectedChain === "Solana") {
      subCoin.length == 1 && setSelectedChain(subCoin[0])
    }
  }, [selectedChain, subCoin, manageChainAmount]);

  useEffect(() => {
    const abc = transactionContent.coin;
    let resultArray;
    if (typeof abc === "string") {
      resultArray = abc.split(",");
    } else {
      resultArray = [abc];
    }
    setSubCoin(resultArray);
  }, []);

  return (
    <>
      <Row gutter={{ xs: 10, sm: 10, md: 14, lg: 20 }} justify="center">
        <Col
          span={9}
          xs={{ span: 24 }}
          sm={{ span: 22 }}
          md={{ span: 20 }}
          lg={{ span: 12 }}
          xl={{ span: 9 }}
        >
          <div className="hLogo mb-4 d-flex justify-content-center">
            <img src={PocketDarkLogo} alt="" />
          </div>
          <div className={classNames(classes.merCard, "merCardCommon")}>
            <h3 className="mb-4 fontWeight800">Proceed to Payment</h3>
            <div className="payList">
              <div className="plItems d-flex justify-content-between">
                <p>Payment Request</p>
                <h4 className="text-end">#8721343</h4>
              </div>
              <div className="plItems d-flex justify-content-between">
                <p>Payment Topic</p>
                <h4 className="text-end">{transactionContent.link_name}</h4>
              </div>
              <div className="plItems d-flex justify-content-between">
                <p>Receiver Email</p>
                <h4 className="text-end">
                  {transactionContent.receiver_email}
                </h4>
              </div>
              <div className="plItems d-flex justify-content-between">
                <p>Receiver Address</p>
                <h4 className="text-end">
                  {shortenAddress(transactionContent.receiver_address)}
                </h4>
              </div>
              {/* <hr className="dashed mt-3 mb-2" /> */}
              <div className="plItems bg-ghostwhite px-3 py-2 mb-3 rounded d-flex justify-content-between align-items-center">
                <h4>Amount</h4>
                <h3 className="text-end fontWeight800">
                  $ {transactionContent.amount}
                </h3>
              </div>
            </div>
            {/* <hr className="dashed mt-2 mb-4" /> */}
            <Row gutter={{ xs: 10, sm: 10, md: 14, lg: 20 }} justify="center">
              <Col
                span={12}
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
              >
                <label>Selected Chain</label>
                <Select
                  showSearch
                  placeholder="Select Chain"
                  className="multiSelect w-100"
                  disabled={true}
                  value={transactionContent.blockchain}
                >
                  <Option
                    key={transactionContent.blockchain}
                    value={transactionContent.blockchain}
                  >
                    <div
                      className="msItems d-flex flex-wrap align-items-center"
                      title={transactionContent.blockchain}
                    >
                      <div className="ncIIcn">
                        {selectedChainIcon(transactionContent.blockchain)}
                      </div>
                      <div className="ncIText">
                        {transactionContent.blockchain}
                      </div>
                    </div>
                  </Option>
                </Select>
              </Col>
              <Col
                span={12}
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
              >
                <label>Selected Coin</label>
                <Select
                  showSearch
                  placeholder="Select Coin"
                  className="multiSelect w-100"
                  onChange={(value) => setSelectedChain(value.toString())}
                  value={subCoin.length == 1 ? subCoin[0] : selectedChain === "Solana" ? "" : selectedChain}
                  disabled={subCoin.length == 1 && true}
                >
                  {subCoin.map((name: any, index: any) => (
                    <Option key={index} value={name}>
                      <div
                        className="msItems d-flex flex-wrap align-items-center"
                        title={name}
                      >
                        <div className="ncIIcn">{selectedChainIcon(name)}</div>
                        <div className="ncIText">{name}</div>
                      </div>
                    </Option>
                  ))}
                </Select>
                {isValidAmount && (
                  Object.keys(qrContent).length > 0 && (
                    <p className='text-center mt-1'>{`${amount} ${qrContent?.blockchain}`}</p>
                  )
                )}
              </Col>
            </Row>
            {isValidAmount && (
              <>
                <hr className="dashed my-3" />
                <ButtonComponent
                  extraClass="w-100"
                  btnLink
                  onClick={() => setShowQR(true)}
                >
                  <QrSvgIcon className="me-2" />
                  Pay using QR
                </ButtonComponent>
                <p className='mt-3 mb-3 text-center'>OR</p>
                <PaymentWalletComponent
                  amount={amount}
                  setIsPaymentSuccess={setIsPaymentSuccess}
                  setManageDigest={setManageDigest}
                  generateJsonContent={qrContent}
                  manageChainAmount={manageChainAmount}
                />
              </>
            )}
          </div>
          <div className="mt-3 d-flex justify-content-center">
            <p className="m-0 text-center smText">
              Powered by <strong>PocketPay Finance</strong>
            </p>
          </div>
        </Col>
      </Row>
      <ModalComponent
        extraClass="payQrModal"
        open={showQR}
        onOk={() => setShowQR(false)}
        onCancel={() => setShowQR(false)}
        footer={null}
        width={400}
      >
        <PaymentQRComponent
          amount={amount}
          setShowQR={setShowQR}
          setIsPaymentSuccess={setIsPaymentSuccess}
          setManageDigest={setManageDigest}
          generateJsonContent={qrContent}
        />
      </ModalComponent>
    </>
  );
};

export default ContentComponent;
