import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import PaymentLinkContainer from "containers/PaymentLinkContainer";
import CheckoutContainer from "containers/CheckoutContainer";
import BridgeContainer from "containers/BridgeContainer";

const BodyComponent = () => {
  return (
    <Router>
      <Routes>
        <Route path="/checkout/:id" element={<CheckoutContainer />} />
        <Route path="/bridge" element={<BridgeContainer />} />
        <Route path="/" element={<PaymentLinkContainer />} />
      </Routes>
    </Router>
  );
};

export default BodyComponent;