import classNames from "classnames";
import { Image } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PocketDarkLogo from "assets/images/logo.svg";
import PocketLightLogo from "assets/images/logo-white.svg";
import { useStyles } from "components/Layout/HeaderComponent/style";

const HeaderComponent = () => {
  const classes = useStyles();
  const getPathName = window.location.pathname;
  const navigate = useNavigate();

  useEffect(() => {
    if (getPathName === "/bridge") {
      document.documentElement.classList.add("bridge-theme");
      document.documentElement.classList.remove("payment-theme");
    }
    if (getPathName === "/") {
      document.documentElement.classList.add("payment-theme");
      document.documentElement.classList.remove("bridge-theme");
    }
  }, []);

  return (
    <div className={classNames(classes.header, "headerCommon")}>
      <div className="container">
        <div className={classNames(classes.headerContainer)}>
          <div className="hLogo">
            <Image
              height={33}
              src={getPathName === "/bridge" ? PocketLightLogo : PocketDarkLogo}
              alt="PocketPay Finance"
              preview={false}
            />
          </div>
          <div className="hLinks">
            <div
              className={getPathName === "/" ? "active hlItems" : "hlItems"}
              title="Payment Link"
              onClick={() => navigate("/")}
            >
              Payment Link
            </div>
            {/* <div
              className={
                getPathName === "/bridge" ? "active hlItems" : "hlItems"
              }
              title="Bridge"
              onClick={() => navigate("/bridge")}
            >
              Bridge
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderComponent;
