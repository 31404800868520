import React, { useEffect, useState } from "react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useWallet } from "@solana/wallet-adapter-react";
import {
  Connection,
  Keypair,
  PublicKey,
  SystemProgram,
  Transaction,
  TransactionInstruction,
} from "@solana/web3.js";
import {
  createAssociatedTokenAccountInstruction,
  getAssociatedTokenAddress,
  createTransferCheckedInstruction
} from "@solana/spl-token";
import axios from 'axios';
import { shortenAddress, solDevnetURL, solMainnetCheckTxn, solTokenAddresses, toastMessage } from 'utils/helper';
import ButtonComponent from 'components/FormsComponents/ButtonComponent';

const SolanaWalletComponent = ({
  amount,
  isLoading,
  generateJsonContent,
  setLoading,
  setManageLabel,
  onPaymentSuccess,
}: any) => {
  const wallet = useWallet();
  const { publicKey, sendTransaction } = useWallet();
  const { connected, disconnect } = useWallet();
  const [walletSteps, setWalletSteps] = useState(0);
  const [btnLoading, setBtnLoading] = useState(false);

  const onCheckTxnSignature = async (getDigest: any) => {
    axios.post(
      solMainnetCheckTxn,
      {
        "jsonrpc": "2.0",
        "id": 1,
        "method": "getSignatureStatuses",
        "params": [
          [`${getDigest}`],
          {
            "searchTransactionHistory": true
          }
        ]
      },
      {
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )
      .then(({ data }: any) => {
        const { result } = data;
        if (result && result.value && result.value[0].err == null) {
          onPaymentSuccess(
            generateJsonContent,
            getDigest,
            publicKey !== null && publicKey.toBase58(),
            generateJsonContent.order_id
          );
          setBtnLoading(false);
        } else if (result.value[0].err) {
          toastMessage('Transaction failed with Insufficient Funds');
          setBtnLoading(false);
        } else {
          toastMessage('Transaction failed with Insufficient Funds');
          setBtnLoading(false);
        }
      })
      .catch((error: any) => {
        toastMessage("Sorry, we're unable to locate this tx hash.");
        setBtnLoading(false);
      });
  }


  const payWithSolTokens = async () => {
    try {
      setBtnLoading(true);

      if (!wallet || !wallet.publicKey) {
        setBtnLoading(false);
        return;
      }
      const getTokenAddress = solTokenAddresses(generateJsonContent.blockchain);
      const connection = new Connection(solDevnetURL, 'confirmed');
      const amountToSend = parseFloat(amount) * 1_000_000;

      const senderTokenAddress = await getAssociatedTokenAddress(
        new PublicKey(getTokenAddress),
        wallet.publicKey
      );
      const recipientTokenAddress = await getAssociatedTokenAddress(
        new PublicKey(getTokenAddress),
        new PublicKey(generateJsonContent.merchant_address)
      );

      const instructions: TransactionInstruction[] = [];
      const senderTokenAccountInfo = await connection.getAccountInfo(senderTokenAddress);
      if (!senderTokenAccountInfo) {
        instructions.push(
          createAssociatedTokenAccountInstruction(
            wallet.publicKey,
            senderTokenAddress,
            wallet.publicKey,
            new PublicKey(getTokenAddress)
          )
        );
      }
      const recipientTokenAccountInfo = await connection.getAccountInfo(recipientTokenAddress);
      if (!recipientTokenAccountInfo) {
        instructions.push(
          createAssociatedTokenAccountInstruction(
            wallet.publicKey,
            recipientTokenAddress,
            new PublicKey(generateJsonContent.merchant_address),
            new PublicKey(getTokenAddress)
          )
        );
      }
      instructions.push(
        createTransferCheckedInstruction(
          senderTokenAddress,
          new PublicKey(getTokenAddress),
          recipientTokenAddress,
          wallet.publicKey,
          amountToSend,
          6
        )
      );
      const transaction = new Transaction().add(...instructions);
      const signature = await sendTransaction(transaction, connection);
      if (signature) {
        setTimeout(() => {
          onCheckTxnSignature(signature);
        }, 2000);
      }
      setBtnLoading(false);
    } catch (error) {
      setBtnLoading(false);
    }
  };

  const payWithSol = async () => {
    try {
      setBtnLoading(true);
      const connection = new Connection(solDevnetURL);
      const lamportsAmount = parseFloat(amount) * 1_000_000_000;
      const transferInstruction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: publicKey as any,
          toPubkey: new PublicKey(generateJsonContent.merchant_address),
          lamports: lamportsAmount,
        })
      );
      const signature = await sendTransaction(transferInstruction, connection);
      if (signature) {
        setTimeout(() => {
          onCheckTxnSignature(signature);
        }, 2000);
      }
    } catch (error: any) {
      setBtnLoading(false);
    }
  }

  const onIdentifyPaymentCall = () => {
    if (generateJsonContent.blockchain == "SOL") {
      payWithSol();
    } else {
      payWithSolTokens();
    }
  }

  useEffect(() => {
    if (walletSteps == 0) {
      if (wallet.connected == true) {
        disconnect();
        setWalletSteps(1);
      } else if (wallet.connected == false) {
        setWalletSteps(1);
      }
    }
  }, [wallet])

  return (
    <div className='d-flex justify-center align-center gap-20'>
      {walletSteps !== 0 && (
        <>
          {wallet.connected == false ? (
            <WalletMultiButton className="btn bg-[#231d48] hover:bg-[#231d48] text-black font-bold py-2 px-4 rounded" />
          ) : (
            <ButtonComponent
              btnSecondaryFill
              onClick={() => { disconnect(); setBtnLoading(false); }}
            >
              {publicKey !== null && shortenAddress(publicKey.toBase58())} Disconnect
            </ButtonComponent>
          )}
        </>
      )}
      {connected && (
        <ButtonComponent
          type="button"
          btnSecondaryFill
          onClick={() => !btnLoading && onIdentifyPaymentCall()}
        >
          {btnLoading ? "Loading..." : "Pay Now"}
        </ButtonComponent>
      )}
    </div>
  );
};

export default SolanaWalletComponent;
